<template>
    <div>
      <nav-header></nav-header>
      <div class="control">
        <div class="envCon" :class="lang">

          <div class="header-nav-title feature">
              <h4>{{$t('control.risk')}}</h4>
              <div v-if="this.$i18n.locale =='en'">
             <p>SOHO China believes effective risk management is crucial and integrates ESG risks into routine risk management, formulates risk management procedures。The ESG risk management working group conducts risk identification and evaluation processes annually according to the risk management procedures, and formulates risk response measures. We establishes a complete risk management system, promotes orderly risk management work, and provides reference for the company's business decisions.
              </p>
            </div>
            <p v-if="this.$i18n.locale=='zh'">
              SOHO 中国认为有效的风险管理至为关键，并将 ESG 风险融入日常风险管理当中，规定风险管理程序。ESG风险管理工作组每年根据风险管理程序开展风险识别、评估流程并制定风险应对措施，建立完整的风险管理体系， 推动风险管理工作有序开展，为公司商业决策提供参考。

            </p>
          </div>
          <div class="feature risk-management-pc">
                  <div v-if="this.$i18n.locale=='zh'">
                    <img src="http://sohochina.oss-cn-beijing.aliyuncs.com/ESG/20240318171719244.png" alt="" style="width:100%;height:auto;">
                </div>
                <div v-else>
                    <img src="http://sohochina.oss-cn-beijing.aliyuncs.com/ESG/20240301173225274.png" alt="" style="width:100%;height:auto;">
                </div>
          </div>

          <div class="feature risk-management-mobile">
                <div v-if="this.$i18n.locale=='zh'">
                  <img src="http://sohochina.oss-cn-beijing.aliyuncs.com/ESG/20240318171759985.png" alt="" style="width:100%;height:auto;">
              </div>
              <div v-else>
                  <img src="http://sohochina.oss-cn-beijing.aliyuncs.com/ESG/20240301173333214.png" alt="" style="width:100%;height:auto;">
              </div>
          </div>

        </div>
      </div>
    </div>
  
  </template>
  
  <script>
  import NavHeader from "../components/Header"
  import {mapState} from  "vuex"
  export default {
    name: "Control",
    components:{
      NavHeader
    },
    mounted() {
      const staggeringOption = {
        delay: 300,
        distance: "50px",
        duration: 500,
        easing: "ease-in-out",
        origin: "bottom",
      };
  
      this.$scroll().reveal(".feature", { ...staggeringOption, interval: 350 });
  
    },
    computed:{
      ...mapState(
          {
            lang:state =>state.lang
          }
      )
  
    },
  }
  </script>
  
  <style scoped lang="scss">
  p{
    text-align: justify;
  }
  .en p{
  
    font-family: "Barlow-Regular";
  }
  .control{
    background-color: #F5F5F7;
    padding-bottom: 100px;
    margin-top: 0px;
  }
  h4{
    font-size: 64px;
    color:rgb(34, 195, 173);
    font-weight: bold;
    text-align: center;
    padding-top: 100px;
    padding-bottom: 0px;
  }
  .header-nav-title h4{
    padding-bottom: 70px;
  }
  .en{
    .dangerSystem{
      ul{
        li{
          font-family: "Barlow-Regular";
        }
      }
    }
  }
  .dangerSystem{
    padding: 0 70px;
    h4{
      padding-bottom: 70px;
    }
    ul{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      li{
        width: 30%;
        background: #ffffff;
        color: #5EC0AE;
        height: 90px;
        line-height: 90px;
        text-align: center;
        font-size: 18px;
        letter-spacing: 1px;
        font-weight: 600;
      }
      li:hover{
        cursor: pointer;
      }
    }
  }
  
  .policy h4{
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .en h4{
    font-family: "BarlowCondensed-bold";
  }
  .control p{
    font-size: 22px;
    line-height:1.6;
    color: #353535;
    padding:0 70px;
  }
  .control .en p{
    font-size: 18px;
  }
  .en .header-nav-title p{
    line-height: 30px;
  }
  .header-nav-title p{
    text-align: justify;
    font-size: 24px;
    line-height: 47px;
    letter-spacing: 1px;
    color: #353535;
  }
  .header-title p{
    font-size: 36px;
  }
  .en .header-title p{
    font-size: 24px;
    font-family: "Barlow-Regular";
  }
  .header-title{
    text-align: center;
    padding: 0 70px;
  }
  .header-title p{
    text-align: center;
  }
  .connect {
    text-align: center;
    padding-top: -10px;
    p{
      text-align: center;
    }
  }
  .en .connect{
    text-align: center;
    padding-top: -10px;
    p{
      text-align: center;
      font-family: "Barlow-Regular";
      line-height: 34px;
    }
  }
  .control img{
    margin: 70px auto 0;
    display: block;
    width: 949px;
    height: 486px;
  }
  .control .juzhen img{
    height: 700px;
  }
  .policy {
    width:100%;
    padding: 0 70px;
    box-sizing: border-box;
  }
  .policy li{
    width: 100%;
    height: 111px;
    line-height: 111px;
    text-align: center;
    font-size: 24px;
    background: #ffffff;
    margin-top: 20px;
    border-radius: 10px;
  }
  .policy a{
    color: #545454;
  }
  .policy ul li:hover{
    cursor: pointer;
    z-index: 2;
    box-shadow: 0 5px 12px 0 rgba(0,0,0,.2);
    transform: translate3d(0,-2px,0);
    transition: all .2s linear;
    color: #2EC4B6;
    font-weight: bold;
  }
  .risk-management-mobile{
    display: none;
  }
  @media (max-width: 960px) {
    .control{
      padding-bottom: 50px;
  
    }
    .en .dangerSystem{
      ul{
        li{
          box-sizing: border-box;
          padding-top: 16px;
          height: 60px;
          font-size: 12px;
          line-height: 1;
        }
      }
    }
    .dangerSystem{
      padding: 0 10px;
      ul{
        li{
          box-sizing: border-box;
          padding-top: 0px;
          height: 60px;
          font-size: 12px;
          line-height: 60px;
        }
      }
    }
    h4{
      font-size: 28px;
      color:rgb(34, 195, 173);
      font-weight: bold;
      text-align: center;
      padding-top: 60px;
      padding-bottom: 23px;
    }
    .header-nav-title h4{
      padding-top: 60px;
      padding-bottom: 40px;
    }
    .policy h4{
      padding-top:40px;
      padding-bottom: 40px;
    }
  
    .control p{
      font-size: 14px;
      line-height: 2;
      color: #353535;
      padding:0 10px;
      text-align: left;
    }
    .control .en p{
      font-size: 14px;
      text-align:left;
    }
    .en .connect p{
      text-align: left;
      line-height: 24px;
    }
  
    .header-title p{
      font-size: 14px;
    }
    .header-title{
      text-align: center;
    }
    .header-title p{
      text-align: center;
    }
    .connect {
      text-align: left;
    }
  
    .control img{
      margin: 20px auto 0;
      display: block;
      width:100%;
      height: auto;
    }
    .control .juzhen img{
      width: 100%;
      display: block;
      height: auto;
    }
    .policy {
      width:100%;
      padding: 0px;
      box-sizing: border-box;
    }
    .policy li{
      width: 96%;
      height: 60px;
      line-height: 60px;
      text-align: center;
      font-size: 16px;
      background: #ffffff;
      border-radius: 10px;
      margin: 0 auto;
      margin-top: 20px;
    }
    .en .policy li{
      font-size: 12px;
    }
    .risk-management-mobile{
    display: block;
    padding: 0 10px;
    }
    .risk-management-pc{
    display: none;
  }
  }
  </style>
  